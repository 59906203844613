import React, { useEffect } from 'react';
import { useLoaderData, Navigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';

import { CostCentersData, EmployeesData } from '../components/user.jsx';

export function HealthcheckTransactions() {
  const [companyCostCenters, companyEmployees] = useLoaderData();
  const [hcResult, setHcResult] = React.useState([]);

  useEffect(() => {
    healthcheckOpen();
  }, []);

  const healthcheckOpen = () => {
    const results = [];
    for (const company of Object.keys(companyEmployees)) {
      const result = { id: uuidv4(), company, ok: 0, nok: 0, log: [] };

      for (const employee of companyEmployees[company]) {
        const costCenter = companyCostCenters[company].find((cc) => cc.code === employee.costcenter);
        const transactions = costCenter ? costCenter.transactions : null;

        if (!transactions) {
          continue;
        }

        for (let i = 0; i < transactions.length; i++) {
          const date = new Date(transactions[i].date);
          transactions[i].date = date;
        }

        transactions.sort((a, b) => a.date - b.date);

        let total = 0;
        let ok = true;
        for (let i = 0; i < transactions.length - 1; i++) {
          const t1 = transactions[i];
          const t2 = transactions[i + 1];

          total += t1.amount;
          if (t1.date.getYear() !== t2.date.getYear()) {
            const year = 1900 + t1.date.getYear();
            if (Math.round(total * 100) / 100 !== 0.0) {
              result.log.push(employee.name + ' total at the end of ' + year + ': ' + total.toFixed(2));
              ok = false;
            }
          }
        }

        if (ok) {
          result.ok += 1;
        } else {
          result.nok += 1;
        }
      }
      results.push(result);
    }
    console.log(results);
    setHcResult(results);
  };

  return (
    <Box sx={{ p: 3 }}>
      {hcResult.length > 0 ? (
        <TableContainer component={Paper} elevation={3}>
          <Table sx={{ minWidth: 650 }} aria-label="healthcheck results">
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: 'bold' }}>Company</TableCell>
                <TableCell align="center" sx={{ fontWeight: 'bold' }}>
                  Status
                </TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>Details</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {hcResult.map((row) => (
                <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="row">
                    <Typography variant="subtitle1">{row.company}</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Chip
                      label={`OK: ${row.ok} | NOK: ${row.nok}`}
                      color={row.nok === 0 ? 'success' : 'error'}
                      variant="outlined"
                    />
                  </TableCell>
                  <TableCell>
                    {row.log.length > 0 ? (
                      <Typography
                        variant="body2"
                        component="pre"
                        sx={{ whiteSpace: 'pre-wrap', fontFamily: 'inherit' }}
                      >
                        {row.log.join('\n')}
                      </Typography>
                    ) : (
                      <Typography variant="body2" color="text.secondary">
                        No issues
                      </Typography>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Typography variant="body1" color="text.secondary">
          No results available.
        </Typography>
      )}
    </Box>
  );
}

export const healthcheckTransactionsLoader = async () => {
  let costCenters = null;
  let employees = null;

  try {
    await CostCentersData().then(function (data) {
      costCenters = data;
    });

    await EmployeesData().then(function (data) {
      employees = data;
    });
  } catch (error) {
    if (error.name === 'AuthenticationError') {
      return <Navigate to={'/login'} state={{ from: '/healthcheck-transactions' }} />;
    }
    throw error;
  }

  return [costCenters, employees];
};
