import React from 'react';
import { useLocation } from 'react-router-dom';
import { EmployeeProfile, EmployeeSalaryCalculation } from '../components/Employee.jsx';
import { Grid } from '@mui/material';

export function Employee() {
  const location = useLocation();
  const employee = location.state?.data[0];
  const transactions = location.state?.data[1];

  return (
    <div>
      <Grid container spacing={1} direction="row" justifyContent="flex-start" alignItems="flex-start">
        <Grid item xs={12} sm={4}>
          {EmployeeProfile(employee, transactions)}
        </Grid>
        <Grid item xs={12} sm={8}>
          {EmployeeSalaryCalculation(employee, transactions)}
        </Grid>
      </Grid>
    </div>
  );
}
