import React, { useState, useEffect } from 'react';
import { 
  Card, 
  CardContent, 
  Typography, 
  Button, 
  Grid, 
  Box, 
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Snackbar,
  Alert,
  Container,
  Skeleton,
  Avatar,
  Divider,
  Paper,
} from '@mui/material';
import { theme } from '../utils/theme.jsx';
import { backend } from '../components/user.jsx';
import { getRequestStatusColor } from '../utils/theme.jsx';
import CancelIcon from '@mui/icons-material/Cancel';
import { useNavigate } from 'react-router-dom';
import RequestPageIcon from '@mui/icons-material/RequestPage';

const RequestCard = ({ request, onCancel }) => {
  const formatDate = (date) => {
    if (!date) return 'Date not available';
    return new Date(date).toLocaleString('sv-SE', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
    });
  };

  const status = request?.status || 'unknown';
  const type = request?.type || 'unknown';
  const amount = request?.amount;
  const requestMessage = request?.requestMessage || '';

  return (
    <Card sx={{
      mb: 3,
      borderRadius: 2,
      boxShadow: theme.shadows[2],
      width: '100%',
      '&:hover': {
        boxShadow: theme.shadows[4],
        transition: 'box-shadow 0.3s'
      }
    }}>
      <Box sx={{ 
        p: 2,
        display: 'flex',
        alignItems: 'center'
      }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <Avatar sx={{ bgcolor: getRequestStatusColor(request.status) }}>
            <RequestPageIcon />
          </Avatar>
          
          <Box>
            <Typography variant="h6">Request #{request.id}</Typography>
            <Typography variant="body2" color="text.secondary">
              {formatDate(request.createdAt)}
            </Typography>
          </Box>
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, ml: 'auto' }}>
          <Chip
            label={request.status.toUpperCase()}
            size="small"
            sx={{
              backgroundColor: getRequestStatusColor(request.status),
              color: 'white',
              fontWeight: 'bold'
            }}
          />
        </Box>
      </Box>

      <Divider />
      
      <Box sx={{ p: 3 }}>
        <Paper elevation={0} sx={{ 
          p: 2,
          borderRadius: 2,
          backgroundColor: theme.palette.grey[50],
          mb: 2
        }}>
          <Typography variant="h6" sx={{ mb: 1 }}>
            {request.type.charAt(0).toUpperCase() + request.type.slice(1)} Request
            {request.amount && ` • ${request.amount} Kr`}
          </Typography>
          
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mb: 2 }}>
            {Array.isArray(request.tags) && request.tags.map((tag, index) => (
              <Chip key={index} label={tag} size="small" variant="outlined" />
            ))}
          </Box>
          
          <Typography variant="body2" color="text.secondary">
            {request.requestMessage}
          </Typography>
        </Paper>

        {request.status !== 'pending' && (
          <Paper elevation={0} sx={{ 
            p: 2,
            borderRadius: 2,
            backgroundColor: theme.palette.grey[50]
          }}>
            <Typography variant="subtitle2" color="text.secondary" gutterBottom>
              Response from {request.updatedBy} • {formatDate(request.updatedAt)}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {request.responseMessage || ''}
            </Typography>
          </Paper>
        )}
        
        {request.status === 'pending' && (
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
            <Button
              variant="contained"
              color="error"
              startIcon={<CancelIcon />}
              onClick={() => onCancel(request.id)}
            >
              Cancel Request
            </Button>
          </Box>
        )}
      </Box>
    </Card>
  );
};

export function MyRequests() {
  const [requests, setRequests] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [openDialog, setOpenDialog] = useState(false);
  const [newRequest, setNewRequest] = useState({
    type: '',
    amount: '',
    requestMessage: '',
  });
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

  useEffect(() => {
    const fetchRequests = async () => {
      try {
        const response = await backend.get('/change-requests');
        if (response.status !== 200) {
          throw new Error('Failed to fetch requests');
        }
        setRequests(response.data || []);
      } catch (err) {
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchRequests();
  }, []);

  const handleCancel = async (requestId) => {
    try {
      const response = await backend.delete(`/change-requests/${requestId}`);
      if (response.status === 200) {
        const refreshResponse = await backend.get('/change-requests');
        setRequests(refreshResponse.data);
        setSnackbar({
          open: true,
          message: 'Request cancelled successfully',
          severity: 'success'
        });
      }
    } catch (err) {
      setSnackbar({
        open: true,
        message: 'Failed to cancel request',
        severity: 'error'
      });
    }
  };

  const handleSubmitRequest = async () => {
    try {
      const response = await backend.post('/change-requests', newRequest);
      if (response.status === 201) {
        const refreshResponse = await backend.get('/change-requests');
        setRequests(refreshResponse.data);
        setOpenDialog(false);
        setNewRequest({ type: '', amount: '', requestMessage: '' });
        setSnackbar({
          open: true,
          message: 'Request submitted successfully!',
          severity: 'success'
        });
      }
    } catch (err) {
      setSnackbar({
        open: true,
        message: 'Failed to create request',
        severity: 'error'
      });
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Box sx={{ display: 'flex', justifyContent: 'flex-start', mb: 3 }}>
        <Button
          variant="contained"
          color="success"
          onClick={() => setOpenDialog(true)}
        >
          Create New Request
        </Button>
      </Box>

      {isLoading ? (
        <Box sx={{ mt: 2 }}>
          {[1, 2, 3].map((i) => (
            <Card key={i} sx={{ mb: 3, p: 3 }}>
              <Skeleton variant="rectangular" height={100} />
            </Card>
          ))}
        </Box>
      ) : (!requests || requests.length === 0) ? (
        <Card sx={{ 
          textAlign: 'center',
          py: 8,
          px: 3,
          borderRadius: 2
        }}>
          <RequestPageIcon sx={{ fontSize: 60, color: theme.palette.grey[300], mb: 2 }} />
          <Typography variant="h5" gutterBottom>No Requests Yet</Typography>
          <Typography variant="body1" color="text.secondary">
            You haven't made any requests yet.
          </Typography>
        </Card>
      ) : (
        requests
          .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
          .map((request) => (
            <RequestCard
              key={request.id}
              request={request}
              onCancel={handleCancel}
            />
          ))
      )}

      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Create New Request</DialogTitle>
        <DialogContent>
          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel>Request Type</InputLabel>
            <Select
              value={newRequest.type}
              label="Request Type"
              onChange={(e) => setNewRequest({ ...newRequest, type: e.target.value })}
            >
              <MenuItem value="salary">Salary</MenuItem>
              <MenuItem value="pension">Pension</MenuItem>
            </Select>
          </FormControl>

          <TextField
            fullWidth
            label="Amount (Kr)"
            type="number"
            value={newRequest.amount}
            onChange={(e) => setNewRequest({ ...newRequest, amount: e.target.value })}
            sx={{ mt: 2 }}
          />

          <TextField
            fullWidth
            label="Message"
            multiline
            rows={4}
            value={newRequest.requestMessage}
            onChange={(e) => setNewRequest({ ...newRequest, requestMessage: e.target.value })}
            sx={{ mt: 2 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
          <Button onClick={handleSubmitRequest} variant="contained">Submit</Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbar.severity}
          sx={{
            width: '100%',
            bgcolor: snackbar.severity === 'error' ? '#FFEBEE' : '#E8F5E9',
            color: snackbar.severity === 'error' ? '#D32F2F' : '#2E7D32',
          }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
} 