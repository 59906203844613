import React from 'react';
import { Modal, Box, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const EnlargedImageModal = ({ open, handleClose, imageSrc }) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="enlarged-image-modal"
      aria-describedby="modal-to-show-enlarged-image"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '75%',
          maxWidth: '750px',
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 1,
        }}
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <img src={imageSrc} alt="Enlarged view" style={{ width: '100%', height: 'auto' }} />
      </Box>
    </Modal>
  );
};

export default EnlargedImageModal;
