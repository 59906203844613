import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { IsAuthenticated } from './user';
import { checkUserRole } from '../utils/rbac';

export function ProtectedRoute({ children, requiredRole }) {
  const location = useLocation();

  if (!IsAuthenticated()) {
    // Redirect to login if not authenticated
    return <Navigate to="/login" state={{ from: location.pathname }} replace />;
  }

  if (requiredRole && !checkUserRole(requiredRole)) {
    // Redirect to unauthorized page if user doesn't have required role
    return <Navigate to="/unauthorized" replace />;
  }

  return children;
}
