import { useEffect } from 'react';
import { useNavigate, useLocation, useLoaderData } from 'react-router-dom';

import { SyncUser, SyncClean } from '../components/user.jsx';
import { config } from '../components/config.jsx';

export function LoginLayout() {
  const { state } = useLocation();

  let loginUrl = new URL(config.dusk_url + '/login');
  loginUrl.searchParams.append('redirect_uri', config.dawn_url);

  loginUrl += '/authenticated';
  if (state?.from) {
    loginUrl += '?state=' + state.from;
  }

  window.location.href = loginUrl.toString();
}

export function LogoutLayout() {
  SyncClean();

  const path = config.dusk_url + '/logout';
  window.location.href = path;
}

export function LoginAuthOk() {
  const from = useLoaderData() || '/home';

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    navigate(from, { replace: true });
  }, [navigate, location]);
}

export const loginAuthOkLoader = async ({ request }) => {
  await SyncUser();
  const url = new URL(request.url);

  return url.searchParams.get('state');
};
