import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useLoaderData, Link, Navigate } from 'react-router-dom';
import { ResponsiveContainer } from 'recharts';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import { CostCentersData } from '../components/user.jsx';

export function CostCenters() {
  const costCenters_ = useLoaderData();

  const costCenters = [];
  for (const company in costCenters_) {
    for (const i in costCenters_[company]) {
      const costCenter = costCenters_[company][i];

      let balance = 0.0;
      for (const j in costCenter.transactions) {
        balance += costCenters_[company][i].transactions[j].amount;
      }

      costCenters.push({
        id: uuidv4(),
        company,
        code: costCenter.code,
        description: costCenter.description,
        note: costCenter.note,
        balance,
        transactions: costCenter.transactions,
      });
    }
  }

  costCenters.sort(function (a, b) {
    return a.code > b.code;
  });

  return (
    <>
      <ResponsiveContainer width="100%">
        <TableContainer component={Paper}>
          <Table size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell className="table-header">Company</TableCell>
                <TableCell className="table-header">CostCenter</TableCell>
                <TableCell className="table-header">Description</TableCell>
                <TableCell className="table-header">Note</TableCell>
                <TableCell className="table-header" align="right">
                  Balance
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {costCenters.map((cc) => (
                <TableRow key={cc.id}>
                  <TableCell component="th" scope="row">
                    {cc.company}
                  </TableCell>
                  <TableCell>
                    <Link to={cc.code} state={{ data: cc }}>
                      {cc.code}
                    </Link>
                  </TableCell>
                  <TableCell>{cc.description}</TableCell>
                  <TableCell>{cc.note}</TableCell>
                  <TableCell align="right">{cc.balance.toLocaleString()}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </ResponsiveContainer>
    </>
  );
}

export const costCentersLoader = async () => {
  try {
    return await CostCentersData();
  } catch (error) {
    if (error.name === 'AuthenticationError') {
      return <Navigate to={'/login'} state={{ from: '/cost-centers' }} />;
    }
    throw error;
  }
};
