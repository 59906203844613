import React from 'react';
import { useLoaderData, Navigate } from 'react-router-dom';
import { Grid } from '@mui/material';

import { EmployeeProfile } from '../components/Employee.jsx';
import { MeData, BalanceAccountData } from '../components/user.jsx';

export function MyProfile() {
  const data = useLoaderData();

  const me = data[0];
  const balanceAccount = data[1];

  if (me == null || balanceAccount == null) {
    return;
  }

  return (
    <Grid container spacing={1} direction="row" justifyContent="flex-start" alignItems="flex-start">
      <Grid item xs={12}>
        {EmployeeProfile(me, balanceAccount)}
      </Grid>
    </Grid>
  );
}

export const myProfileLoader = async () => {
  let me = null;
  let transactions = null;

  try {
    await MeData().then(function (data) {
      me = data;
    });

    await BalanceAccountData().then(function (data) {
      transactions = data;
    });
  } catch (error) {
    if (error.name === 'AuthenticationError') {
      return <Navigate to={'/login'} state={{ from: '/me' }} />;
    }
    throw error;
  }

  return [me, transactions];
};
