import { IsEmployee, IsAdmin, IsSysAdmin, IsShopkeeper } from '../components/user.jsx';

const ROLES = {
  USER: 'user',
  ADMIN: 'admin',
  SYS_ADMIN: 'sys-admin',
  SHOPKEEPER: 'shopkeeper',
};

const roleCheckers = {
  [ROLES.USER]: IsEmployee,
  [ROLES.ADMIN]: IsAdmin,
  [ROLES.SYS_ADMIN]: IsSysAdmin,
  [ROLES.SHOPKEEPER]: IsShopkeeper,
};

export function checkUserRole(requiredRole) {
  const checker = roleCheckers[requiredRole];
  return checker ? checker() : false;
}

export { ROLES };
