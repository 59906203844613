import React from 'react';
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Divider,
  Button,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import CloseIcon from '@mui/icons-material/Close';

export default function ShoppingCart({ cart, removeFromCart, updateQuantity, clearCart, closeCart, placeOrder }) {
  const totalPrice = cart.reduce((sum, item) => sum + item.price * item.quantity, 0);

  return (
    <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Typography variant="h6">Shopping Cart</Typography>
        <IconButton onClick={closeCart}>
          <CloseIcon />
        </IconButton>
      </Box>
      {cart.length === 0 ? (
        <Typography>Your cart is empty.</Typography>
      ) : (
        <>
          <List sx={{ flexGrow: 1, overflowY: 'auto' }}>
            {cart.map((item) => (
              <React.Fragment key={`${item.id}-${item.sizeId}`}>
                <ListItem>
                  <ListItemText
                    primary={`${item.name} - Size: ${item.sizeName}`}
                    secondary={`${item.price.toFixed(2)} Kr each`}
                  />
                  <Box sx={{ display: 'flex', alignItems: 'center', mr: 2 }}>
                    <IconButton
                      size="small"
                      onClick={() => updateQuantity(item.id, item.attributeId, item.quantity - 1)}
                      disabled={item.quantity <= 1}
                    >
                      <RemoveIcon fontSize="small" />
                    </IconButton>
                    <Typography sx={{ mx: 1 }}>{item.quantity}</Typography>
                    <IconButton
                      size="small"
                      onClick={() => updateQuantity(item.id, item.attributeId, item.quantity + 1)}
                    >
                      <AddIcon fontSize="small" />
                    </IconButton>
                  </Box>
                  <ListItemSecondaryAction>
                    <IconButton edge="end" onClick={() => removeFromCart(item.id, item.attributeId)}>
                      <DeleteIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
                <Divider />
              </React.Fragment>
            ))}
          </List>
          <Box sx={{ mt: 2 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
              <Typography variant="subtitle1" fontWeight="bold">
                Total: {totalPrice.toFixed(2)} Kr
              </Typography>
              <Button variant="outlined" color="secondary" onClick={clearCart}>
                Clear Cart
              </Button>
            </Box>
            <Button
              variant="contained"
              color="success"
              fullWidth
              onClick={() => {
                placeOrder(cart);
                closeCart();
              }}
            >
              Place Order
            </Button>
          </Box>
        </>
      )}
    </Box>
  );
}
