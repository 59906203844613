import { createTheme } from '@mui/material/styles';
import { green } from '@mui/material/colors';

export const theme = createTheme({
  palette: {
    primary: {
      main: green[500],
    },
    other: {
      linkAdmin: 'var(--linkAdminColor)',
    },
    requestStatus: {
      pending: '#87CEEB',
      accepted: '#B7E5B4',
      completed: '#D3D3D3',
      rejected: '#FF6961',
    },
  },
  root: {
    width: '0.5rem',
  },
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: '1em',
          color: 'black',
          backgroundColor: 'lightgray',
          maxWidth: 400,
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          backgroundColor: 'var(--background)',
        },
        list: {
          '&[role="menu"]': {
            color: 'white',
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: 'var(--sinixOrange)',
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          color: 'white',
          padding: '1.4em',
          '&:hover': {
            backgroundColor: 'var(--sinixOrange)',
          },
        },
      },
    },
  },
});

export const getRequestStatusColor = (status) => {
  switch (status) {
    case 'pending':
      return theme.palette.requestStatus.pending;
    case 'accepted':
      return theme.palette.requestStatus.accepted;
    case 'rejected':
      return theme.palette.requestStatus.rejected;
    case 'completed':
      return theme.palette.requestStatus.completed;
    default:
      return 'default';
  }
};
