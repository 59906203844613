import axios from 'axios';
import { config } from '../components/config.jsx';

export const backend = axios.create({
  withCredentials: true,
  baseURL: config.dusk_url + '/sunshine',
});

const localStorageKeyUserInfo = 'user.info';
const localStorageKeyMessageError = 'msg.error';

function sessionStorageGet(key) {
  const d = window.sessionStorage.getItem(key);
  return d ? JSON.parse(d) : null;
}

function sessionStorageStore(key, data) {
  window.sessionStorage.setItem(key, JSON.stringify(data));
}

function clearCacheAndSessionStorage() {
  window.localStorage.clear();
  window.sessionStorage.clear();
}

export async function refreshSession() {
  return false;
}

class AuthenticationError extends Error {
  constructor(message) {
    super(message);
    this.name = 'AuthenticationError';
  }
}

async function backendRequest(url) {
  try {
    const response = await backend.get(url);
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      clearCacheAndSessionStorage();
      throw new AuthenticationError('User not authenticated');
    }
    throw error; // Re-throw other errors
  }
}

export async function userRequest(url) {
  if (IsAuthenticated()) {
    return backendRequest(url);
  }
  throw new AuthenticationError('User not authenticated');
}

async function adminRequest(url) {
  if (IsAuthenticated() && IsAdmin()) {
    return backendRequest(url);
  }
  throw new Error('User not authenticated or not an admin');
}

function getUserInfoData() {
  return sessionStorageGet(localStorageKeyUserInfo);
}

export function GetAndClearMessageError() {
  const msg = sessionStorageGet(localStorageKeyMessageError);
  sessionStorageStore(localStorageKeyMessageError, null);

  return msg;
}

async function fetchAndStoreUserInfoData() {
  clearCacheAndSessionStorage();

  try {
    const response = await backend.get('/info');
    if (response.status === 200) {
      // Extract integration information into a map
      const integrationMap = {};
      if (Array.isArray(response.data.integrations)) {
        response.data.integrations.forEach((integration) => {
          if (integration && integration.name && integration.color) {
            integrationMap[integration.name] = integration.color;
          }
        });
      }

      // Store the user info data
      sessionStorageStore(localStorageKeyUserInfo, response.data);
    } else {
      sessionStorageStore(localStorageKeyMessageError, 'message failed with: ' + response.message);
    }
  } catch (error) {
    if (error.response) {
      sessionStorageStore(localStorageKeyMessageError, 'message failed with: ' + error.message);
    } else {
      sessionStorageStore(localStorageKeyMessageError, 'no response');
    }
  }
}

export async function SyncUser() {
  clearCacheAndSessionStorage();

  return await fetchAndStoreUserInfoData();
}

export async function SyncClean() {
  clearCacheAndSessionStorage();
}

export async function MeData() {
  return userRequest('/me');
}

export async function BalanceAccountData() {
  return userRequest('/balance-account');
}

export async function CostCentersData() {
  return adminRequest('/cost-centers');
}

export async function EmployeesData() {
  return adminRequest('/employees');
}

export async function ExpenseReportsData() {
  return userRequest('/expense-reports');
}

export async function AnalyticsData() {
  return adminRequest('/analytics');
}

export async function AdminData() {
  if (IsAuthenticated() && IsSysAdmin()) {
    return backendRequest('fortnox');
  }
  throw new Error('User not authenticated or not a sys-admin');
}

export function IsAuthenticated() {
  const user = getUserInfoData();

  return user != null;
}

export function IsEmployee() {
  const user = getUserInfoData();

  return user && user.roles && user.roles.includes('user');
}

export function IsAdmin() {
  const user = getUserInfoData();

  return user && user.roles && (user.roles.includes('admin') || user.roles.includes('sys-admin'));
}

export function IsSysAdmin() {
  const user = getUserInfoData();

  return user && user.roles && user.roles.includes('sys-admin');
}

export function IsShopkeeper() {
  const user = getUserInfoData();

  return user && user.roles && (user.roles.includes('sys-admin') || user.roles.includes('shopkeeper'));
}

export function Username() {
  const user = getUserInfoData();

  return user ? user.Name : '';
}

export function LastLogin() {
  const user = getUserInfoData();

  return user && 'lastlogin' in user ? new Date(user.lastlogin) : Date.now();
}

export const IntegrationColor = (integration) => {
  const user = getUserInfoData();

  if (integration === 'group') {
    return 'var(--sinixOrange)';
  }
  return user && user.integrations && integration in user.integrations
    ? user.integrations[integration].color
    : '#000000';
};

export function Integrations() {
  const user = getUserInfoData();

  return user && user.integrations ? user.integrations : [];
}
