import React from 'react';
import { useLocation } from 'react-router-dom';
import ToggleButton from '@mui/material/ToggleButton';
import { ThemeProvider } from '@mui/material/styles';

import { TransactionsGraph, TransactionsTable } from '../components/Transactions.jsx';
import { LastLogin } from '../components/user.jsx';

import { theme } from '../utils/theme.jsx';

export function CostCenter() {
  const [selected, setSelected] = React.useState(false);

  const location = useLocation();
  const costCenter = location.state?.data;
  const lastLogin = LastLogin();

  return (
    <ThemeProvider theme={theme}>
      <ToggleButton
        value="check"
        selected={selected}
        color="primary"
        onChange={() => {
          setSelected(!selected);
        }}
      >
        Include account 7219 (R-lön)
      </ToggleButton>
      <h2>{costCenter.description}</h2>
      <h2>{costCenter.note}</h2>
      <div>{TransactionsGraph(costCenter.transactions, selected)}</div>
      <div>{TransactionsTable(costCenter.transactions, lastLogin, selected)}</div>
    </ThemeProvider>
  );
}
