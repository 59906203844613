import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { Tooltip as MuiTooltip } from '@mui/material';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { useLocalStorage } from '../utils/localStorage.js';

import { theme } from '../utils/theme.jsx';

function valToString(val, digits) {
  return val.toLocaleString('default', {
    minimumFractionDigits: digits,
    maximumFractionDigits: digits,
  });
}

const employerCostMultiplier = 0.3142; // Arbetsgivaravgifter
const pensionCostMultiplier = 0.2425; // Särskild löneskatt
const itpThreshold = 46438.0;
const itpLow = 0.045;
const itpHigh = 0.3;
const workingDaysPerYear = 251.0;
const workingHoursPerDay = 8.0;
const nonWorkingDaysPerYear = 25;

function EmployeePension(salary) {
  let pensionCost = Math.min(salary, itpThreshold) * itpLow;
  pensionCost += salary > itpThreshold ? (salary - itpThreshold) * itpHigh : 0.0;

  return pensionCost;
}

function EmployeePensionCost(pension) {
  return pension * (1 + pensionCostMultiplier);
}

function EmployeePayrollTax(salary) {
  return salary * employerCostMultiplier;
}

function EmployeeMISR(monthlyIncome, monthlyExpenses) {
  return monthlyIncome > 0 ? monthlyExpenses / monthlyIncome : 0.0;
}

function EmployeeBASR(monthlyExpenses, balance) {
  return monthlyExpenses > 0 ? balance / monthlyExpenses : 0.0;
}

export function EmployeeSalaryBalanceData(employee, transactions) {
  let balance = 0.0;
  if (transactions) {
    for (let i = 0; i < transactions.length; ++i) {
      if (transactions[i].account === '7219') {
        continue;
      }
      balance += transactions[i].amount;
    }
  }

  const hourlyIncome = employee.assignments.length > 0 
    ? employee.assignments[employee.assignments.length - 1].hourlyrate 
    : 0.0;

  const income = calculateTotalIncome(workingDaysPerYear, nonWorkingDaysPerYear, workingHoursPerDay, hourlyIncome);
  const expenses = calculateTotalExpenses(employee.salary, EmployeePension(employee.salary), 0);

  const accountBalanceSafetyRatio = EmployeeBASR(expenses, balance);
  const salarySafetyRatio = EmployeeMISR(income, expenses);

  return [balance, accountBalanceSafetyRatio, salarySafetyRatio];
}

function formatNumber(value, decimals) {
  return typeof value === 'number' && !isNaN(value) ? value.toFixed(decimals) : value;
}

function tooltipBASR(data) {
  return (
    <MuiTooltip
      theme={theme}
      title={
        <>
          <h3>Balance-Account-to-Salary-Ratio (BASR)</h3>
          <p>
            BASR are your monthly expenses* divided by your current account balance that gives you a rough estimation of
            the number of months your current account balance covers. For example, a BASR of 4.0 should cover your
            monthly expenses* for four months without any additional income.
          </p>
          <br />
          <p>(*) monthly expenses in the BASR calculation includes salary, payroll tax, and pension</p>
        </>
      }
    >
      {data}
    </MuiTooltip>
  );
}

function tooltipMISR(data) {
  return (
    <MuiTooltip
      theme={theme}
      title={
        <>
          <h3>Monthly-Income-to-Salary-Ratio (MISR)</h3>
          <p>
            MISR is your monthly income divided by your monthly expenses* that gives you a rough estimation of how your
            balance account will grow, or shrink if MISR &gt; 1, over time. The MISR calculation is using hourly rate
            multipled with the number of work hours per month.
          </p>
          <br />
          <p>(*) monthly expenses in the MISR calculation includes salary, payroll tax, and pension</p>
        </>
      }
    >
      {data}
    </MuiTooltip>
  );
}

function tooltipFixedExpenses(data) {
  return (
    <MuiTooltip
      theme={theme}
      title={
        <>
          <h3>Fixed expenses</h3>
          <p>Fixed reoccuring expenses that e.g. company car.</p>
        </>
      }
    >
      {data}
    </MuiTooltip>
  );
}

export function EmployeeInformation({ employee, transactions }) {
  const [balance, accountBalanceSafetyRatio, salarySafetyRatio] = EmployeeSalaryBalanceData(employee, transactions);

  const InfoItem = ({ label, value }) => (
    <Box sx={{ textAlign: 'center', mb: 2 }}>
      <Typography variant="caption" color="textSecondary" sx={{ fontSize: '0.9rem' }}>
        {label}
      </Typography>
      <Typography variant="body1" sx={{ fontWeight: 500, fontSize: '1.1rem' }}>
        {value}
      </Typography>
    </Box>
  );

  const SectionBox = ({ title, children }) => (
    <Box 
      sx={{ 
        p: 3, 
        height: '100%',
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        borderRadius: 2,
        boxShadow: '0 1px 3px rgba(0,0,0,0.1)',
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <Typography variant="h6" sx={{ textAlign: 'center', mb: 2, fontWeight: 'bold' }}>
        {title}
      </Typography>
      {children}
    </Box>
  );

  const assignments = employee.assignments.map(assignment => (
    <Box key={assignment.name} sx={{ mb: 1.5 }}>
      <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
        {assignment.name}
      </Typography>
      <Typography variant="body2" color="text.secondary">
        Hourly rate: {valToString(assignment.hourlyrate, 0)} Kr
      </Typography>
    </Box>
  )).filter(Boolean);

  return (
    <Box 
      sx={{
        maxWidth: '1200px',
        margin: '0 auto',
        p: 4,
      }}
    >
      <Grid container spacing={3}>
        {/* Personal Information */}
        <Grid item xs={12} md={4}>
          <SectionBox title="Personal Information">
            <InfoItem label="Name" value={employee.name} />
            <InfoItem label="Email" value={employee.email} />
            <InfoItem label="Phone" value={employee?.phone || 'unknown'} />
          </SectionBox>
        </Grid>

        {/* Address Details */}
        <Grid item xs={12} md={4}>
          <SectionBox title="Address Details">
            <InfoItem label="Address" value={employee.address} />
            <InfoItem label="Post code" value={employee.postcode} />
            <InfoItem label="City" value={employee.city} />
            <InfoItem label="Country" value={employee.country} />
          </SectionBox>
        </Grid>

        {/* Employment Details */}
        <Grid item xs={12} md={4}>
          <SectionBox title="Employment Details">
            <InfoItem label="Employed since" value={employee.employmentdate} />
            <InfoItem label="Salary" value={valToString(employee.salary, 0)} />
            <InfoItem label="Balance account" value={valToString(balance, 0)} />
          </SectionBox>
        </Grid>

        {/* Financial Ratios */}
        <Grid item xs={12} md={6}>
          <SectionBox title="Financial Ratios">
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              {tooltipBASR(
                <Box sx={{ textAlign: 'center' }}>
                  <Typography variant="caption" color="textSecondary" sx={{ fontSize: '0.9rem' }}>
                    Balance Account to Salary ratio
                  </Typography>
                  <Typography variant="body1" sx={{ fontWeight: 500, fontSize: '1.1rem' }}>
                    {accountBalanceSafetyRatio.toFixed(2)}
                  </Typography>
                </Box>
              )}
            </Box>
            <Box sx={{ mt: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              {tooltipMISR(
                <Box sx={{ textAlign: 'center' }}>
                  <Typography variant="caption" color="textSecondary" sx={{ fontSize: '0.9rem' }}>
                    Monthly Income to Salary ratio
                  </Typography>
                  <Typography variant="body1" sx={{ fontWeight: 500, fontSize: '1.1rem' }}>
                    {salarySafetyRatio.toFixed(2)}
                  </Typography>
                </Box>
              )}
            </Box>
          </SectionBox>
        </Grid>

        {/* Assignments */}
        <Grid item xs={12} md={6}>
          <SectionBox title="Assignments">
            {assignments.length > 0 ? (
              <Box sx={{ mt: 1, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                {assignments.map(assignment => (
                  <Box key={assignment.key} sx={{ textAlign: 'center', mb: 1.5 }}>
                    {assignment}
                  </Box>
                ))}
              </Box>
            ) : (
              <Typography variant="body1" color="text.secondary" sx={{ textAlign: 'center' }}>
                No assignments
              </Typography>
            )}
          </SectionBox>
        </Grid>
      </Grid>
    </Box>
  );
}

function calculateTotalIncome(workingDaysPerYear, nonWorkingDays, workingHours, hourlyRate) {
  // Convert inputs to numbers and validate
  const days = Number(workingDaysPerYear);
  const nonDays = Number(nonWorkingDays);
  const hours = Number(workingHours);
  const rate = Number(hourlyRate);

  if (isNaN(days) || isNaN(nonDays) || isNaN(hours) || isNaN(rate)) {
    return 0;
  }

  return ((days - nonDays) / 12) * hours * rate * 0.8;
}

function calculateTotalExpenses(salary, pension, fixedExpenses) {
  // Convert inputs to numbers and validate
  const salaryNum = Number(salary);
  const pensionNum = Number(pension);
  const fixedNum = Number(fixedExpenses);

  if (isNaN(salaryNum) || isNaN(pensionNum) || isNaN(fixedNum)) {
    return 0;
  }

  return salaryNum + EmployeePayrollTax(salaryNum) + EmployeePensionCost(pensionNum) + fixedNum;
}

export function SalaryCalculationTable({ employee, transactions }) {
  const hashfunc = function (s) {
    return s.split('').reduce(function (a, b) {
      a = (a << 5) - a + b.charCodeAt(0);
      return a & a;
    }, 0);
  };

  const hash = hashfunc(employee.email);
  const [balance] = EmployeeSalaryBalanceData(employee, transactions);

  const [ldSalary, setldSalary] = useLocalStorage(`${hash}.meLdSalary`, employee.salary);
  const [ldPension, setldPension] = useLocalStorage(`${hash}.meLdPension`, EmployeePension(employee.salary));
  const [ldFixed, setldFixed] = useLocalStorage(`${hash}.meLdFixed`, 0);
  const [ldNonWorkingDays, setldNonWorkingDays] = useLocalStorage(`${hash}.meLdNonWorkingDays`, nonWorkingDaysPerYear);
  const [ldHourlyRate, setldHourlyRate] = useLocalStorage(
    `${hash}.meLdHourlyRate`,
    employee.assignments.length ? employee.assignments[employee.assignments.length - 1].hourlyrate : 0
  );
  const [ldWorkingHours, setldWorkingHours] = useLocalStorage(`${hash}.meLdWorkingHours`, workingHoursPerDay);

  const setSalary = (salary) => {
    setldSalary(salary);
    salary = Number(salary);
    if (!isNaN(salary)) {
      setldPension(EmployeePension(salary));
    }
  };

  const totalIncome = () => {
    return calculateTotalIncome(workingDaysPerYear, ldNonWorkingDays, ldWorkingHours, ldHourlyRate);
  };

  const totalExpenses = () => {
    return calculateTotalExpenses(ldSalary, ldPension, ldFixed);
  };

  const tooltipPension = (data) => {
    return (
      <MuiTooltip
        theme={theme}
        title={
          <>
            <h3>Monthly pension</h3>
            <p>Monthly pension expenses where the default value follows the ITP1 plan. The value can be overwritten.</p>
          </>
        }
      >
        {data}
      </MuiTooltip>
    );
  };

  const tooltipIncome = (data) => {
    return (
      <MuiTooltip
        theme={theme}
        title={
          <>
            <h3>Monthly income</h3>
            <p>
              <b>Calculation:</b>
            </p>
            <table>
              <tr>
                <td>workingDaysPerYear:</td>
                <tr>{workingDaysPerYear}</tr>
              </tr>
              <tr>
                <td>nonWorkingDaysPerYear:</td>
                <tr>{ldNonWorkingDays}</tr>
              </tr>
              <tr>
                <td>workHoursPerDay:</td>
                <tr>{ldWorkingHours}</tr>
              </tr>
              <tr>
                <td>hourlyRate:</td>
                <tr>{ldHourlyRate}</tr>
              </tr>
            </table>
            <br />
            <p>
              monthlyIncome = (workingDaysPerYear - nonWorkingDaysPerYear) / 12 * workHoursPerDay * hourlyRate * 80%
            </p>
          </>
        }
      >
        {data}
      </MuiTooltip>
    );
  };

  const tooltipExpenses = (data) => {
    return (
      <MuiTooltip
        theme={theme}
        title={
          <>
            <h3>Monthly expense</h3>
            <p>
              <b>Calculation:</b>
            </p>
            <table>
              <tr>
                <td>salary:</td>
                <tr>{ldSalary}</tr>
              </tr>
              <tr>
                <td>payrollTax (arbetsgivaravgift):</td>
                <tr>{(employerCostMultiplier * 100).toFixed(2)}%</tr>
              </tr>
              <tr>
                <td>pension:</td>
                <tr>{formatNumber(ldPension, 0)}</tr>
              </tr>
              <tr>
                <td>pensionTax (särskild löneskatt):</td>
                <tr>{formatNumber(ldPension * pensionCostMultiplier, 0)}</tr>
              </tr>
              <tr>
                <td>fixedExpenses:</td>
                <tr>{ldFixed}</tr>
              </tr>
            </table>
            <br />
            <p>monthlyExpense = salary * (1 + payrollTax) + pension * (1 + pensionTax) + fixedExpenses</p>
          </>
        }
      >
        {data}
      </MuiTooltip>
    );
  };

  return (
    <Box mx={1} p={3} gap={2} my={1}>
      <Grid container spacing={3} sx={{ mt: 2 }}>
        <Grid item xs={12}>
          <h4>Input parameters</h4>
        </Grid>
        
        {/* First row of inputs */}
        <Grid item xs={12} md={4}>
          <TextField
            fullWidth
            label="Salary"
            value={ldSalary}
            variant="outlined"
            size="small"
            helperText={isNaN(ldSalary) ? 'Incorrect entry.' : ''}
            onChange={(event) => {
              setSalary(event.target.value);
            }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            fullWidth
            label="Hourly rate"
            value={formatNumber(ldHourlyRate, 0)}
            variant="outlined"
            size="small"
            helperText={isNaN(ldHourlyRate) ? 'Incorrect entry.' : ''}
            onChange={(event) => {
              setldHourlyRate(event.target.value);
            }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          {tooltipPension(
            <TextField
              fullWidth
              label="Pension"
              value={formatNumber(ldPension, 0)}
              variant="outlined"
              size="small"
              helperText={isNaN(ldPension) ? 'Incorrect entry.' : ''}
              onChange={(event) => {
                setldPension(event.target.value);
              }}
            />
          )}
        </Grid>

        {/* Second row of inputs */}
        <Grid item xs={12} md={4}>
          {tooltipFixedExpenses(
            <TextField
              fullWidth
              label="Fixed expenses"
              value={formatNumber(ldFixed, 0)}
              variant="outlined"
              size="small"
              helperText={isNaN(ldFixed) ? 'Incorrect entry.' : ''}
              onChange={(event) => {
                setldFixed(event.target.value);
              }}
            />
          )}
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            fullWidth
            label="Non working days"
            value={formatNumber(ldNonWorkingDays, 0)}
            variant="outlined"
            size="small"
            helperText={isNaN(ldNonWorkingDays) ? 'Incorrect entry.' : ''}
            onChange={(event) => {
              setldNonWorkingDays(event.target.value);
            }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            fullWidth
            label="Work hours per day"
            value={formatNumber(ldWorkingHours, 0)}
            variant="outlined"
            size="small"
            helperText={isNaN(ldWorkingHours) ? 'Incorrect entry.' : ''}
            onChange={(event) => {
              setldWorkingHours(event.target.value);
            }}
          />
        </Grid>

        {/* Results section */}
        <Grid item xs={12} sx={{ mt: 2 }}>
          <h4>Calculation result</h4>
        </Grid>
        
        <Grid item xs={12} md={4}>
          {tooltipIncome(
            <TextField
              fullWidth
              label="Income"
              value={valToString(totalIncome(), 0)}
              variant="outlined"
              size="small"
              InputProps={{ readOnly: true }}
            />
          )}
        </Grid>
        <Grid item xs={12} md={4}>
          {tooltipExpenses(
            <TextField
              fullWidth
              label="Expenses"
              value={valToString(totalExpenses(), 0)}
              variant="outlined"
              size="small"
              InputProps={{ readOnly: true }}
            />
          )}
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            fullWidth
            label="Diff"
            value={valToString(totalIncome() - totalExpenses(), 0)}
            variant="outlined"
            size="small"
            InputProps={{ readOnly: true }}
          />
        </Grid>

        {/* Ratios */}
        <Grid item xs={12} md={6}>
          {tooltipMISR(
            <TextField
              fullWidth
              label="MISR"
              value={formatNumber(EmployeeMISR(totalIncome(), totalExpenses()), 2)}
              variant="outlined"
              size="small"
              InputProps={{ readOnly: true }}
            />
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          {tooltipBASR(
            <TextField
              fullWidth
              label="BASR"
              value={formatNumber(EmployeeBASR(totalExpenses(), balance), 2)}
              variant="outlined"
              size="small"
              InputProps={{ readOnly: true }}
            />
          )}
        </Grid>
      </Grid>
    </Box>
  );
}

export function EmployeeProfile(employee, transactions) {
  return (
    <ThemeProvider theme={theme}>
      <EmployeeInformation employee={employee} transactions={transactions} />
    </ThemeProvider>
  );
}

export function EmployeeSalaryCalculation(employee, transactions) {
  return (
    <ThemeProvider theme={theme}>
      <SalaryCalculationTable employee={employee} transactions={transactions} />
    </ThemeProvider>
  );
}
