import React from 'react';
import { config } from '../components/config.jsx';

import SlackLogo from '../images/slack.png';

export const Home = () => {
  console.log(
    config.dusk_url +
      '/login?redirect_uri=' +
      config.dawn_url +
      '/sdaklfjsadflsajdfklsadfjsaldkfjsadlfjsdalfkjaldfksafweyhowancaoshdgsa'
  );

  return (
    <>
      <br />
      <br />
      <div className="slack">
        <img width="100px" src={SlackLogo} />
        <a href="https://sinixab.slack.com/archives/C052LUP1X29" target="_blank" rel="noreferrer">
          #sunshine
        </a>
      </div>
    </>
  );
};
