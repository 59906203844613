import React, { useState, useEffect } from 'react';
import {
  Typography,
  Box,
  Card,
  CardContent,
  Button,
  Grid,
  Chip,
  Avatar,
  AvatarGroup,
  Container,
  Skeleton,
  IconButton,
  Divider,
  Paper,
} from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useNavigate } from 'react-router-dom';
import { backend } from '../components/user.jsx';
import EnlargedImageModal from '../components/EnlargedImageModal.jsx';
import { theme, getRequestStatusColor } from '../utils/theme.jsx';

const ArticleCard = ({ article }) => {
  const [selectedImage, setSelectedImage] = useState(null);

  return (
    <Paper elevation={0} sx={{ 
      p: 2,
      borderRadius: 2,
      backgroundColor: theme.palette.grey[50],
      '&:hover': {
        backgroundColor: theme.palette.grey[100],
        transition: 'background-color 0.3s'
      }
    }}>
      <Box sx={{ display: 'flex', gap: 2 }}>
        <AvatarGroup
          max={1}
          sx={{
            '& .MuiAvatar-root': { width: 80, height: 80, borderRadius: 2 }
          }}
        >
          {article.images?.map((image, index) => (
            <Avatar
              key={index}
              alt={image.name}
              src={image.url}
              onClick={() => setSelectedImage(image)}
              sx={{ cursor: 'pointer' }}
            />
          ))}
        </AvatarGroup>
        
        <Box sx={{ flex: 1 }}>
          <Typography variant="h6" sx={{ mb: 0.5 }}>{article.name}</Typography>
          <Typography variant="body2" color="text.secondary" gutterBottom>
            {article.collection}
          </Typography>
          
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, my: 1 }}>
            {article.attributes.map((attribute) => (
              <Chip
                key={attribute.name}
                label={`${attribute.name}: ${attribute.quantity}`}
                size="small"
                variant="outlined"
              />
            ))}
          </Box>
          
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
            <Typography variant="body2" color="text.secondary">
              Price per item: {article.price.toFixed(2)} Kr
            </Typography>
            <Typography variant="subtitle2" color="primary">
              Total: {(article.price * article.attributes.reduce((sum, attr) => sum + attr.quantity, 0)).toFixed(2)} Kr
            </Typography>
          </Box>
        </Box>
      </Box>
      
      <EnlargedImageModal
        open={Boolean(selectedImage)}
        handleClose={() => setSelectedImage(null)}
        imageSrc={selectedImage?.url || ''}
      />
    </Paper>
  );
};

const OrderCard = ({ order, onCancelOrder }) => {
  const totalAmount = order.articles.reduce((sum, article) => {
    const articleTotal = article.price * article.attributes.reduce(
      (attrSum, attr) => attrSum + attr.quantity, 0
    );
    return sum + articleTotal;
  }, 0);

  return (
    <Card sx={{
      mb: 3,
      borderRadius: 2,
      boxShadow: theme.shadows[2],
      width: '100%',
      '&:hover': {
        boxShadow: theme.shadows[4],
        transition: 'box-shadow 0.3s'
      }
    }}>
      <Box sx={{ 
        p: 2,
        display: 'flex',
        alignItems: 'center'
      }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <Avatar sx={{ bgcolor: getRequestStatusColor(order.status) }}>
            <ShoppingBagIcon />
          </Avatar>
          
          <Box>
            <Typography variant="h6">Order #{order.id}</Typography>
            <Typography variant="body2" color="text.secondary">
              {new Date(order.timestamp).toLocaleDateString('sv-SE', {
                year: 'numeric',
                month: 'long',
                day: 'numeric'
              })}
            </Typography>
          </Box>
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, ml: 'auto' }}>
          <Chip
            label={order.status.toUpperCase()}
            size="small"
            sx={{
              backgroundColor: getRequestStatusColor(order.status),
              color: 'white',
              fontWeight: 'bold'
            }}
          />
        </Box>
      </Box>

      <Divider />
      <Box sx={{ p: 3 }}>
        <Grid container spacing={2}>
          {order.articles.map((article) => (
            <Grid item xs={12} key={article.id}>
              <ArticleCard article={article} />
            </Grid>
          ))}
        </Grid>
        
        <Box sx={{ 
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mt: 3
        }}>
          <Typography variant="h6">
            Total Amount: {totalAmount.toFixed(2)} Kr
          </Typography>
          
          {order.status === 'pending' && (
            <Button
              variant="contained"
              color="error"
              startIcon={<CancelIcon />}
              onClick={() => onCancelOrder(order.id)}
            >
              Cancel Order
            </Button>
          )}
        </Box>
      </Box>
    </Card>
  );
};

const EmptyState = () => {
  const navigate = useNavigate();
  
  return (
    <Card sx={{ 
      textAlign: 'center',
      py: 8,
      px: 3,
      borderRadius: 2
    }}>
      <ShoppingBagIcon sx={{ fontSize: 60, color: theme.palette.grey[300], mb: 2 }} />
      <Typography variant="h5" gutterBottom>No Orders Yet</Typography>
      <Typography variant="body1" color="text.secondary" sx={{ mb: 3 }}>
        Your order history is empty. Start shopping to see your orders here!
      </Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={() => navigate('/shop')}
      >
        Start Shopping
      </Button>
    </Card>
  );
};

export function MyOrders() {
  const [orderList, setOrderList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await backend.get('/shop/orders');
        if (response.status !== 200) throw new Error('Failed to fetch orders');
        setOrderList(response.data.sort((a, b) => Number(b.id) - Number(a.id)));
      } catch (err) {
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    };
    fetchOrders();
  }, []);

  const handleCancelOrder = async (orderId) => {
    try {
      const response = await backend.delete(`/shop/orders/${orderId}`);
      if (response.status !== 200) throw new Error('Failed to cancel order');
      setOrderList((prev) => prev.filter((order) => order.id !== orderId));
    } catch (err) {
      console.error('Error cancelling order:', err);
      setError(`Failed to cancel order ${orderId}: ${err.message}`);
    }
  };

  if (error) {
    return (
      <Container maxWidth="lg" sx={{ mt: 4 }}>
        <Alert severity="error">{error}</Alert>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      {isLoading ? (
        <Box sx={{ mt: 2 }}>
          {[1, 2, 3].map((i) => (
            <Card key={i} sx={{ mb: 3, p: 3 }}>
              <Skeleton variant="rectangular" height={100} />
            </Card>
          ))}
        </Box>
      ) : orderList.length === 0 ? (
        <EmptyState />
      ) : (
        orderList.map((order) => (
          <OrderCard
            key={order.id}
            order={order}
            onCancelOrder={handleCancelOrder}
          />
        ))
      )}
    </Container>
  );
}
