import React from 'react';
import { useLoaderData, Navigate } from 'react-router-dom';

import { TransactionsGraph, TransactionsTable } from '../components/Transactions.jsx';
import { BalanceAccountData, LastLogin } from '../components/user.jsx';

export function BalanceAccount() {
  const transactions = useLoaderData();
  const lastLogin = LastLogin();

  return (
    <div style={{ width: '100%', padding: '20px' }}>
      {TransactionsGraph(transactions, false)}
      {TransactionsTable(transactions, lastLogin, false)}
    </div>
  );
}

export const balanceAccountLoader = async () => {
  try {
    return await BalanceAccountData();
  } catch (error) {
    if (error.name === 'AuthenticationError') {
      return <Navigate to={'/login'} state={{ from: '/balance-account' }} />;
    }
    throw error;
  }
};
